exports.components = {
  "component---src-pages-3rd-party-integration-js": () => import("./../../../src/pages/3rd-party-integration.js" /* webpackChunkName: "component---src-pages-3rd-party-integration-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-consulting-js": () => import("./../../../src/pages/it-consulting.js" /* webpackChunkName: "component---src-pages-it-consulting-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-web-and-mobile-application-js": () => import("./../../../src/pages/web-and-mobile-application.js" /* webpackChunkName: "component---src-pages-web-and-mobile-application-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

